/**
 * MinersFactory | impressum page
 */

import React from "react"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"

const ImpressumPage = () => (
    <SubPage>
        <SEO
            title="Impressum"
            meta={[
                {
                    name: `robots`,
                    content: `noindex`,
                },
            ]}
        />
        <div className="container">
            <section>
                <h1>Impressum</h1>
                <p>
                    <b>
                        Inhaltlich verantwortlich für den Web-Auftritt unter
                        minersfactory.de ist:
                    </b>
                </p>
                <div>
                    <p>Kevin Pfeifer</p>
                    <p>Str. der Jugend 85</p>
                    <p>08228 Rodewisch</p>
                    <p>Deutschland</p>
                </div>
                <p>
                    <b>Kontaktdaten:</b>
                </p>
                <div>
                    <p>
                        E-Mail:{" "}
                        <a href="mailto:kevin@minersfactory.de">
                            kevin@minersfactory.de
                        </a>
                    </p>
                    <p>
                        Tel.: <a href="tel:+4937443648590">+49 3744 3648590</a>
                    </p>
                </div>
                <br />
                <p>
                    Die Weiterverbreitung der auf dieser Internetseite
                    veröffentlichten Inhalte erfordert die schriftliche
                    Zustimmung des Betreibers. Für Verweise (sog. Links) auf
                    externe Internetseiten wird trotz sorgfältigster Kontrolle
                    keine inhaltliche oder vergleichbare Haftung übernommen.
                </p>
            </section>
        </div>
    </SubPage>
)

export default ImpressumPage
