/**
 * MinersFactory | navigation component
 */

import React, { Component } from "react"
import { Link } from "gatsby"

class Navigation extends Component {
    state = {
        showNav: true,
        lastOffset: Number.MAX_SAFE_INTEGER
    }

    componentDidMount() {
        window.onscroll = () => {
            const yOffs = window.pageYOffset;

            if(this.state.lastOffset < yOffs && yOffs > 444) {
                this.setState({
                    showNav: false,
                    lastOffset: yOffs
                })
            } else {
                this.setState({
                    showNav: true,
                    lastOffset: yOffs
                })
            }
        }
    }

    render() {
        return (
            <nav className={this.state.showNav ? "navbar navbar-expand-lg fixed-top navbar-dark bg-custom" : "navbar navbar-expand-lg fixed-top navbar-dark bg-custom navbar-hidden"}>
                <Link to="/" className="navbar-brand">
                    MinersFactory
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <Link
                            to="/"
                            className="nav-item nav-link"
                            activeClassName="active"
                        >
                            Startseite <span className="sr-only">(current)</span>
                        </Link>
                        <Link
                            to="/news/"
                            className="nav-item nav-link"
                            activeClassName="active"
                        >
                            News-Updates
                        </Link>
                        <Link
                            to="/faq/"
                            className="nav-item nav-link"
                            activeClassName="active"
                        >
                            wichtige Fragen und Antworten
                        </Link>
                        <Link
                            to="/team/"
                            className="nav-item nav-link"
                            activeClassName="active"
                        >
                            Server-Team
                        </Link>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navigation
